/*eslint-disable max-len*/
import type { CSSProperties, SVGProps } from 'react'

// this file is not .svg because babel had issues parsing the style attributes with custom properties.
// (.svg files that are imported as modules get transformed into jsx under the hood)
// another thing: if you want to display the animated version of this, you have to place the svg in the dom as a <svg> instead of an <img> tag
// that's why we need this file
export const KirDevLogo = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="kir-dev-svg" data-name="Layer 1" viewBox="0 0 2834.65 2004.09" {...props}>
    <defs>
      <style>{`
      #kir-dev-svg path, #kir-dev-svg rect, #kir-dev-svg polygon {
        stroke: black;
        fill: black;
        stroke-width: 10;
      }

      .chakra-ui-dark #kir-dev-svg path, .chakra-ui-dark #kir-dev-svg rect, .chakra-ui-dark #kir-dev-svg polygon {
        stroke: #EBECEC;
        fill: #EBECEC;
      }

      #kir-dev-svg path.colored, #kir-dev-svg rect.colored, #kir-dev-svg polygon.colored {
        stroke: #F15A29;
        fill: #F15A29;
      }

      @media not (prefers-reduced-motion) {
        svg .animate-svg-path {
        stroke-dasharray: var(--svg-path-length);
        stroke-dashoffset: var(--svg-path-length);
        animation: animate-svg 1s ease forwards;
      }

        @keyframes animate-svg {
          from {
          fill-opacity: 0;
          stroke-opacity: 0.5;
        }
          25% {
          stroke-opacity: 1;
          fill-opacity: 0;

        }
          to {
          stroke-dashoffset: 0;
          fill-opacity: 1;
        }
      }
    }
    `}</style>
    </defs>
    <title>kirdev</title>
    <path
      d="M1574.37,109.54c52.51,0,105.16-1.19,157.65.21a561.05,561.05,0,0,1,59.62,4.76l-14.55-2c39.5,5.38,78.38,15.22,115.19,30.64l-13.08-5.52a365.07,365.07,0,0,1,80.66,46.57l-11.08-8.56a316.8,316.8,0,0,1,56.88,56.6l-8.57-11.08a289.6,289.6,0,0,1,37.2,63.54l-5.52-13.08a273.81,273.81,0,0,1,19,69.15q-1-7.29-2-14.56a263.89,263.89,0,0,1,0,69.68q1-7.28,2-14.56a260.29,260.29,0,0,1-17.81,65.26l5.52-13.09a267.94,267.94,0,0,1-34.41,58.9l8.56-11.09a292.26,292.26,0,0,1-52.21,52l11.08-8.56a347.73,347.73,0,0,1-76.93,44.41l13.08-5.52c-37.5,15.74-77.2,25.56-117.45,31l14.56-2c-32.64,4.32-65.36,5.23-98.23,5.23H1574.37c-29.86,0-54.77,24.92-54.77,54.77s24.91,54.77,54.77,54.77c50.8,0,101.62.42,152.41,0,90.08-.8,182.45-18.63,261.15-64.06,60.44-34.89,113.61-88.33,141.44-153,7.41-17.22,14.24-34.38,18.78-52.59s7.07-36.74,8.63-55.27c2.73-32.47-.88-65.94-8.41-97.55-15.81-66.37-54.66-128.12-105.6-173.15-61.11-54-137.73-88.1-217.82-102A667.8,667.8,0,0,0,1710.13,0H1574.37c-29.85,0-54.77,24.92-54.77,54.77s24.92,54.77,54.77,54.77Z"
      className="animate-svg-path"
      style={{ '--svg-path-length': 3438 } as CSSProperties}
    />
    <path
      d="M1243.91,599.21a540.71,540.71,0,0,0-298.65,91.16A606.59,606.59,0,0,0,738.63,928.51a672.69,672.69,0,0,0-67.18,292.29c0,29.85,24.95,54.77,54.78,54.77S781,1250.65,781,1220.8a637.17,637.17,0,0,1,5.64-84.08l-1.95,14.56A609.18,609.18,0,0,1,826.85,997l-5.52,13.08A581.91,581.91,0,0,1,895.8,882.41l-8.57,11.09a546.71,546.71,0,0,1,97.52-97.72l-11.09,8.57a509.11,509.11,0,0,1,111.41-65.24L1072,744.63a485,485,0,0,1,122.73-33.58l-14.56,2a490.86,490.86,0,0,1,63.76-4.24c29.85,0,54.77-24.88,54.77-54.77s-24.92-54.82-54.78-54.78Z"
      className="animate-svg-path"
      style={{ '--svg-path-length': 2220 } as CSSProperties}
    />
    <path
      d="M1353.69,54.77v1166c0,29.85,24.92,54.77,54.77,54.77s54.78-24.92,54.78-54.77V54.77c0-29.85-24.92-54.77-54.78-54.77s-54.77,24.91-54.77,54.77Z"
      className="animate-svg-path"
      style={{ '--svg-path-length': 2676 } as CSSProperties}
    />
    <path
      className="animate-svg-path colored"
      d="M671.31,54.79c.14,115.35,27.12,229.68,80,332.27,48.5,94.16,121.12,176,209.78,234.05a521.59,521.59,0,0,0,282.72,84.63c29.86,0,54.78-25,54.78-54.77s-24.92-54.72-54.78-54.77a467.45,467.45,0,0,1-60.62-4l14.56,2a465.38,465.38,0,0,1-117.23-32l13.09,5.52a499.52,499.52,0,0,1-110.86-65.05l11.09,8.57a555.88,555.88,0,0,1-97.93-98.41l8.56,11.08a615.64,615.64,0,0,1-79.34-136.41l5.53,13.09a664.5,664.5,0,0,1-45.53-167.7L787,147.36a704.18,704.18,0,0,1-6.19-92.57C780.82,24.93,756,0,726.08,0s-54.8,24.91-54.77,54.77Z"
      style={{ '--svg-path-length': 2268 } as CSSProperties}
    />
    <path
      className="animate-svg-path colored"
      d="M2170.1,1220.8c-.15-111.42-29.14-222.25-84.46-319-52.61-92-129.8-170.59-221.32-224.12a578.89,578.89,0,0,0-290-78.59c-29.86,0-54.77,24.95-54.77,54.77s24.92,54.73,54.77,54.77a531.64,531.64,0,0,1,69.86,4.7l-14.56-2a523,523,0,0,1,132.42,36.35L1749,742.18a539.37,539.37,0,0,1,118,69.14l-11.09-8.57A563,563,0,0,1,1956,903l-8.57-11.08a578.69,578.69,0,0,1,74.78,128.23l-5.52-13.08a589.73,589.73,0,0,1,40.53,149.12q-1-7.28-2-14.56a609.93,609.93,0,0,1,5.26,79.21c0,29.85,24.88,54.77,54.77,54.77s54.81-24.92,54.77-54.77Z"
      style={{ '--svg-path-length': 2256 } as CSSProperties}
    />
    <polygon
      className="animate-svg-path colored"
      points="715.44 1927.08 702.45 1872.27 684.86 1872.27 671.74 1926.87 656.2 1872.27 633.68 1872.27 662.36 1966.69 683.26 1966.69 693.97 1922.48 704.94 1966.69 725.11 1966.69 753.79 1872.27 731.29 1872.27 715.44 1927.08"
      style={{ '--svg-path-length': 619 } as CSSProperties}
    />
    <path
      className="animate-svg-path colored"
      d="M824.68,1878.34l-.09-.07a32.89,32.89,0,0,0-11.94-5.11,61.19,61.19,0,0,0-14.4-1.75,41.46,41.46,0,0,0-13.86,2.22,31.4,31.4,0,0,0-11,6.58,29,29,0,0,0-7.14,10.47,35.12,35.12,0,0,0-2.43,13.2v34.92c.26,10.48,3.59,18.24,9.88,23,6,4.58,13.93,6.9,23.67,6.9a62.42,62.42,0,0,0,17.2-2.48,69.87,69.87,0,0,0,16.13-6.86l3.63-2.12L825,1940l-3.81,2a57.65,57.65,0,0,1-12.1,4.79,43.85,43.85,0,0,1-11,1.57,17.35,17.35,0,0,1-9.88-2.59c-1.29-.88-3-2.58-3-7.12V1935l51.38-3.77v-26.16a43.59,43.59,0,0,0-2.17-14.1C832.82,1886.42,829.56,1882.17,824.68,1878.34Zm-39.5,36.29v-10.75a12.18,12.18,0,0,1,1.32-6.18,10.24,10.24,0,0,1,3.4-3.59,14.56,14.56,0,0,1,5-1.91,31,31,0,0,1,5.85-.61,25.78,25.78,0,0,1,4.64.53,14.23,14.23,0,0,1,4.73,1.85,10.46,10.46,0,0,1,5.11,9.91v8.49Z"
      style={{ '--svg-path-length': 479 } as CSSProperties}
    />
    <path
      className="animate-svg-path colored"
      d="M925.6,1881a33.11,33.11,0,0,0-10.42-6.52,34.28,34.28,0,0,0-12.67-2.36h0a66.7,66.7,0,0,0-15.81,1.56q-4,.87-7.21,1.7V1838H859l0,4.3c-.12,11.66-.24,22.28-.35,31.94,0,4,0,8-.09,12-.06,3.77-.09,7.43-.09,10.87v69.15l3.9.41c4.41.46,9.21.9,14.27,1.3s10.35.61,15.3.61a100.85,100.85,0,0,0,14.43-1,36.89,36.89,0,0,0,12.34-3.81,32.15,32.15,0,0,0,12.37-11.22c2.89-4.62,4.35-10.56,4.35-17.65v-30.86a31.48,31.48,0,0,0-2.67-13A31.14,31.14,0,0,0,925.6,1881Zm-24.83,11.29A20.37,20.37,0,0,1,908,1894a10.68,10.68,0,0,1,4.48,3.8,12,12,0,0,1,1.63,6.74v32.24c0,3.27-.92,5.64-2.79,7.24a21.26,21.26,0,0,1-8.92,4.31,52.68,52.68,0,0,1-12.23,1.34h-.79c-3.45,0-6.75-.17-9.86-.4v-54c1.15-.41,2.47-.8,3.93-1.18a73.71,73.71,0,0,1,8.63-1.61A44.64,44.64,0,0,1,900.77,1892.27Z"
      style={{ '--svg-path-length': 558 } as CSSProperties}
    />
    <path
      className="animate-svg-path colored"
      d="M1074.7,1861.53c0,2.29,0,4.65-.09,7s-.09,4.46-.09,6.38v.46q-3.3-.86-7.21-1.71a66.33,66.33,0,0,0-14.31-1.58l-1.4,0a34.59,34.59,0,0,0-12.77,2.36,33.11,33.11,0,0,0-10.42,6.52,31,31,0,0,0-9.83,23.07v30.86c0,7.09,1.46,13,4.35,17.65a32,32,0,0,0,12.34,11.2,36.76,36.76,0,0,0,12.37,3.83,100.65,100.65,0,0,0,14.43,1c4.93,0,10-.21,15.21-.61s9.87-.84,14.35-1.3l3.91-.4V1838h-20.41Zm-18.28,30.63a44.42,44.42,0,0,1,5.54.34,71.68,71.68,0,0,1,8.63,1.62c1.46.37,2.78.76,3.94,1.17v54c-3.18.23-6.51.37-10,.41h-.76a52.31,52.31,0,0,1-12.16-1.34,21,21,0,0,1-8.92-4.31c-1.88-1.6-2.79-4-2.79-7.24v-32.24a12,12,0,0,1,1.63-6.73A10.67,10.67,0,0,1,1046,1894a20.42,20.42,0,0,1,7.19-1.75C1054.29,1892.19,1055.37,1892.16,1056.42,1892.16Z"
      style={{ '--svg-path-length': 558 } as CSSProperties}
    />
    <path
      className="animate-svg-path colored"
      d="M1173.93,1878.34l-.1-.07a33,33,0,0,0-11.94-5.11,61.19,61.19,0,0,0-14.4-1.75,41.46,41.46,0,0,0-13.86,2.22,31.5,31.5,0,0,0-11,6.58,29,29,0,0,0-7.14,10.47,35.12,35.12,0,0,0-2.42,13.2v34.92c.26,10.48,3.59,18.24,9.88,23,6,4.58,13.93,6.9,23.67,6.9a62.42,62.42,0,0,0,17.2-2.48,70.31,70.31,0,0,0,16.13-6.86l3.63-2.12-9.32-17.26-3.81,2a57.24,57.24,0,0,1-12.09,4.79,44,44,0,0,1-11,1.57,17.29,17.29,0,0,1-9.87-2.59c-1.3-.88-3-2.58-3-7.12V1935l51.37-3.77v-26.16a43.64,43.64,0,0,0-2.17-14.1C1182.06,1886.42,1178.8,1882.17,1173.93,1878.34Zm-39.51,36.29v-10.75a12.18,12.18,0,0,1,1.32-6.18,10.24,10.24,0,0,1,3.4-3.59,14.56,14.56,0,0,1,5-1.91,31,31,0,0,1,5.85-.61,25.78,25.78,0,0,1,4.64.53,14.23,14.23,0,0,1,4.73,1.85,11.66,11.66,0,0,1,3.7,3.7,11.53,11.53,0,0,1,1.41,6.21v8.49Z"
      style={{ '--svg-path-length': 479 } as CSSProperties}
    />
    <polygon
      className="animate-svg-path colored"
      points="1239.88 1930.21 1221.95 1872.27 1199.1 1872.27 1228.75 1966.69 1250.78 1966.69 1280.81 1872.27 1257.95 1872.27 1239.88 1930.21"
      style={{ '--svg-path-length': 388 } as CSSProperties}
    />
    <path
      className="animate-svg-path colored"
      d="M1350.05,1878.34l-.1-.07a32.81,32.81,0,0,0-11.94-5.11,61.12,61.12,0,0,0-14.4-1.75,41.46,41.46,0,0,0-13.86,2.22,31.5,31.5,0,0,0-11,6.58,29,29,0,0,0-7.14,10.47,35.12,35.12,0,0,0-2.42,13.2v34.92c.26,10.48,3.59,18.24,9.88,23,6,4.58,13.93,6.9,23.67,6.9a62.54,62.54,0,0,0,17.21-2.48,70,70,0,0,0,16.12-6.86l3.63-2.12-9.32-17.26-3.81,2a57.24,57.24,0,0,1-12.09,4.79,44,44,0,0,1-11,1.57,17.29,17.29,0,0,1-9.87-2.59c-1.3-.88-3-2.58-3-7.12V1935l51.37-3.77v-26.16a43.59,43.59,0,0,0-2.17-14.1C1358.18,1886.42,1354.92,1882.17,1350.05,1878.34Zm-39.51,36.29v-10.75a12.18,12.18,0,0,1,1.32-6.18,10.24,10.24,0,0,1,3.4-3.59,14.64,14.64,0,0,1,5-1.91,31.12,31.12,0,0,1,5.85-.61,25.78,25.78,0,0,1,4.64.53,14.23,14.23,0,0,1,4.73,1.85,10.46,10.46,0,0,1,5.11,9.91v8.49Z"
      style={{ '--svg-path-length': 479 } as CSSProperties}
    />
    <rect
      className="animate-svg-path colored"
      x="1385.41"
      y="1837.98"
      width="21.54"
      height="128.72"
      style={{ '--svg-path-length': 301 } as CSSProperties}
    />
    <path
      className="animate-svg-path colored"
      d="M1495.34,1886.7a29.35,29.35,0,0,0-6.21-7.34,31.06,31.06,0,0,0-7.89-4.74,48.25,48.25,0,0,0-8.33-2.36,56.19,56.19,0,0,0-19.54,0,39.29,39.29,0,0,0-7.75,2.39,32.31,32.31,0,0,0-7.84,4.66,29.93,29.93,0,0,0-6.4,7.33,35.44,35.44,0,0,0-4.23,10.35,56.15,56.15,0,0,0-1.45,13.39v21.27c0,10.92,3.18,19.86,9.44,26.54s15.88,10.21,28.34,10.21c13,0,22.54-3.34,28.47-9.93,5.06-5.6,8-13.15,8.62-22.48h.16v-25.61a59.79,59.79,0,0,0-1.35-13.43A35.34,35.34,0,0,0,1495.34,1886.7ZM1447.06,1933v-24.35a20.6,20.6,0,0,1,1.47-8.34,15.24,15.24,0,0,1,3.67-5.21,12.92,12.92,0,0,1,5-2.77,22.2,22.2,0,0,1,6.25-.94,20.35,20.35,0,0,1,6,.92,12.34,12.34,0,0,1,4.78,2.75,14.73,14.73,0,0,1,3.51,5.18,21.76,21.76,0,0,1,1.41,8.41V1933a21,21,0,0,1-1.18,7.71,10.49,10.49,0,0,1-2.91,4.23,11.23,11.23,0,0,1-4.61,2.28,28.65,28.65,0,0,1-7,.81,27.73,27.73,0,0,1-7.22-.88,12.32,12.32,0,0,1-4.84-2.46,11.64,11.64,0,0,1-3.12-4.41A18.56,18.56,0,0,1,1447.06,1933Z"
      style={{ '--svg-path-length': 440 } as CSSProperties}
    />
    <path
      className="animate-svg-path colored"
      d="M1591.53,1886.77a27.64,27.64,0,0,0-6.17-7.41,30.82,30.82,0,0,0-7.88-4.74,48.25,48.25,0,0,0-8.33-2.36,56.19,56.19,0,0,0-19.54,0,39.4,39.4,0,0,0-7.76,2.39,32.25,32.25,0,0,0-7.83,4.66,29.93,29.93,0,0,0-6.4,7.33,35.28,35.28,0,0,0-4.23,10.35,56.15,56.15,0,0,0-1.45,13.39v89.24l20.46,4.46.17-38.77a31.75,31.75,0,0,0,4.86,1.59,52.12,52.12,0,0,0,12.29,1.5c11.94,0,21.2-3.23,27.54-9.6s9.53-15.27,9.53-26.47v-21.95a59.69,59.69,0,0,0-1.35-13.42A37,37,0,0,0,1591.53,1886.77Zm-20.25,58a11.63,11.63,0,0,1-4.64,2.43,26.16,26.16,0,0,1-6.92.87c-5.06,0-9.07-1.17-12.25-3.58-2.81-2.12-4.17-5.76-4.17-11.11v-24.69a20.6,20.6,0,0,1,1.47-8.34,15.22,15.22,0,0,1,3.66-5.21,13.06,13.06,0,0,1,5-2.77,22.32,22.32,0,0,1,6.26-.94,20.4,20.4,0,0,1,6,.92,12.3,12.3,0,0,1,4.77,2.75,14.73,14.73,0,0,1,3.51,5.18,21.56,21.56,0,0,1,1.42,8.41v24.69a18.62,18.62,0,0,1-1.16,7.07A11.39,11.39,0,0,1,1571.28,1944.76Z"
      style={{ '--svg-path-length': 524 } as CSSProperties}
    />
    <path
      className="animate-svg-path colored"
      d="M1680.28,1878.34l-.1-.07a33.08,33.08,0,0,0-11.94-5.11,61.12,61.12,0,0,0-14.4-1.75,41.5,41.5,0,0,0-13.86,2.22,31.5,31.5,0,0,0-11,6.58,28.94,28.94,0,0,0-7.13,10.47,35.12,35.12,0,0,0-2.43,13.2v34.92c.27,10.48,3.59,18.24,9.88,23,6,4.58,13.93,6.9,23.67,6.9a62.54,62.54,0,0,0,17.21-2.48,70.5,70.5,0,0,0,16.13-6.86l3.62-2.12L1680.6,1940l-3.81,2a57.78,57.78,0,0,1-12.09,4.79,43.92,43.92,0,0,1-11,1.57,17.31,17.31,0,0,1-9.87-2.59c-1.3-.88-3-2.58-3-7.12V1935l51.37-3.77v-26.16A43.64,43.64,0,0,0,1690,1891C1688.41,1886.42,1685.15,1882.17,1680.28,1878.34Zm-39.51,36.29v-10.75a12.28,12.28,0,0,1,1.32-6.18,10.24,10.24,0,0,1,3.4-3.59,14.64,14.64,0,0,1,5-1.91,31.12,31.12,0,0,1,5.85-.61,25.88,25.88,0,0,1,4.65.53,14.62,14.62,0,0,1,4.73,1.85,10.48,10.48,0,0,1,5.1,9.91v8.49Z"
      style={{ '--svg-path-length': 479 } as CSSProperties}
    />
    <path
      className="animate-svg-path colored"
      d="M1757.55,1872.1a37.09,37.09,0,0,0-7.47-.69,44.28,44.28,0,0,0-12.21,1.89,48.4,48.4,0,0,0-5.37,1.91l-.28-2.94h-19.39v94.42h21.37v-65.9a4.31,4.31,0,0,1,1-2.63,13.89,13.89,0,0,1,3.63-3.32,19.14,19.14,0,0,1,5.46-2.37,25,25,0,0,1,6.51-.88,16.21,16.21,0,0,1,2.9.36,43.77,43.77,0,0,1,4.64,1.17l4.17,1.27,5.9-19.66-4.38-1.12C1761.76,1873,1759.59,1872.52,1757.55,1872.1Z"
      style={{ '--svg-path-length': 294 } as CSSProperties}
    />
    <path
      className="animate-svg-path colored"
      d="M1857,1838h-20.84v4.35c0,4.3,0,8.9-.08,13.66l-.36,29.06c0,4.57-.08,9.12-.08,13.51v39.38c-.26,9,2,16.19,6.61,21.52a29.46,29.46,0,0,0,18.16,9.75l3.6.58,6.13-17.63-4.06-1.45c-6.28-2.25-9.08-5.85-9.08-11.68v-45.89h21.29v-20.87H1857Z"
      style={{ '--svg-path-length': 352 } as CSSProperties}
    />
    <path
      className="animate-svg-path colored"
      d="M1952.28,1878.34l-.09-.07a32.86,32.86,0,0,0-12-5.11,61,61,0,0,0-14.39-1.75,41.46,41.46,0,0,0-13.86,2.22,31.4,31.4,0,0,0-11,6.58,28.88,28.88,0,0,0-7.14,10.47,35.12,35.12,0,0,0-2.43,13.2v34.92c.27,10.48,3.6,18.24,9.88,23,6,4.58,13.94,6.9,23.67,6.9a62.44,62.44,0,0,0,17.21-2.48,70.06,70.06,0,0,0,16.13-6.86l3.63-2.12-9.32-17.26-3.81,2a57.53,57.53,0,0,1-12.1,4.79,43.85,43.85,0,0,1-11,1.57,17.35,17.35,0,0,1-9.88-2.59c-1.29-.88-3-2.58-3-7.12V1935l51.38-3.77v-26.16A43.64,43.64,0,0,0,1962,1891C1960.42,1886.42,1957.16,1882.17,1952.28,1878.34Zm-39.5,36.29v-10.75a12.18,12.18,0,0,1,1.32-6.18,10.31,10.31,0,0,1,3.39-3.59,14.72,14.72,0,0,1,5-1.91,31.13,31.13,0,0,1,5.86-.61,25.78,25.78,0,0,1,4.64.53,14,14,0,0,1,4.73,1.86,11.2,11.2,0,0,1,3.69,3.69,11.44,11.44,0,0,1,1.42,6.21v8.49Z"
      style={{ '--svg-path-length': 479 } as CSSProperties}
    />
    <path
      className="animate-svg-path colored"
      d="M2059.27,1891.56a41.58,41.58,0,0,0-3.15-5.39l-.18-.24a31.86,31.86,0,0,0-13.62-10c-5.18-2-11.81-3.37-19.73-4l-4-.3-3.08,18.34a40.15,40.15,0,0,0-12,2.43,28.46,28.46,0,0,0-13.66,10.1c-3.48,4.74-5.24,11.26-5.24,19.37v13c0,7.08,1.47,13,4.36,17.65a32,32,0,0,0,12.33,11.2,36.83,36.83,0,0,0,12.37,3.83,100.69,100.69,0,0,0,14.44,1c4.95,0,10.06-.21,15.21-.62s9.84-.83,14.35-1.29l3.9-.4v-63.16a31.72,31.72,0,0,0-.52-6A23.16,23.16,0,0,0,2059.27,1891.56Zm-18.66,23.72v34c-3.17.23-6.5.37-9.95.41h-.75a52.43,52.43,0,0,1-12.18-1.34,21.08,21.08,0,0,1-8.92-4.31c-1.87-1.6-2.79-4-2.79-7.24v-14.4c0-3.38.56-6,1.6-7.47a8.74,8.74,0,0,1,3.75-3.27,12.51,12.51,0,0,1,5-1c2.16,0,3.9,0,5.18.15l.57,0a55.45,55.45,0,0,1,11,2A70.17,70.17,0,0,1,2040.61,1915.28Z"
      style={{ '--svg-path-length': 433 } as CSSProperties}
    />
    <path
      className="animate-svg-path colored"
      d="M2201,1902.84c-.4-9.32-3.52-17-9.28-22.7s-13.79-8.73-23.74-8.73a53,53,0,0,0-14.56,2,98.34,98.34,0,0,0-9.91,3.37,25.59,25.59,0,0,0-8.28-3.91,40.19,40.19,0,0,0-25.32,1.36c-2.17.85-4.29,1.75-6.36,2.68l-.23-4.61h-19.92v94.42h21.37V1951.2c0-4.75,0-10.13-.09-16-.06-5.7-.09-12-.09-19.24v-18.48c2.69-1.32,5.27-2.53,7.71-3.61a26,26,0,0,1,8.84-2.12c1.74,0,3.59.06,5.52.17a7,7,0,0,1,6.24,4.11c.54,1,1.17,3,1.17,7v63.67h21.37V1903a50.08,50.08,0,0,0-.69-8.54c1.4-.48,2.81-.94,4.19-1.36a47.55,47.55,0,0,1,6.69-1.53,44.3,44.3,0,0,1,4.51.39,13.94,13.94,0,0,1,4.69,1.51,9.5,9.5,0,0,1,3.43,3.28c.92,1.38,1.38,3.48,1.38,6.25v63.67H2201v-63.85Z"
      style={{ '--svg-path-length': 705 } as CSSProperties}
    />
    <path
      d="M862.44,1498.29h-81.5l-3.64,3c-9.56,7.83-18.67,15.37-27.17,22.5-7.25,6.19-15.14,12.81-23.43,19.67l-.07.06q0-6-.08-13.37c-.14-24.55-.5-59.1-1-102.68l-.17-13H670.51v321.26h56.25v-115l72.25,115h68L767.87,1580.9Z"
      className="animate-svg-path"
      style={{ '--svg-path-length': 1342 } as CSSProperties}
    />
    <path
      d="M944.9,1414.45a33.31,33.31,0,1,0,23.63,9.75A32.69,32.69,0,0,0,944.9,1414.45Z"
      className="animate-svg-path"
      style={{ '--svg-path-length': 210 } as CSSProperties}
    />
    <rect
      x="916.56"
      y="1498.71"
      width="56.67"
      height="237"
      className="animate-svg-path"
      style={{ '--svg-path-length': 588 } as CSSProperties}
    />
    <path
      d="M1152.59,1498.36a90.81,90.81,0,0,0-18.52-1.76,106.35,106.35,0,0,0-30.1,4.77c-3.31,1-6.52,2.1-9.6,3.32l-.56-6h-51.69v237h56.67V1571.28a8.37,8.37,0,0,1,1.91-5.11,31.22,31.22,0,0,1,8-7.52,42.79,42.79,0,0,1,12.25-5.44,55,55,0,0,1,14.79-2,36.83,36.83,0,0,1,6.35.82,99.71,99.71,0,0,1,10.78,2.8l12.8,4,15.57-53.25-13.08-3.44C1162.7,1500.65,1157.46,1499.4,1152.59,1498.36Z"
      className="animate-svg-path"
      style={{ '--svg-path-length': 737 } as CSSProperties}
    />
    <rect
      x="1235.92"
      y="1585.5"
      width="164.53"
      height="57.09"
      className="animate-svg-path"
      style={{ '--svg-path-length': 444 } as CSSProperties}
    />
    <path
      d="M1606.26,1474.57v.24c0,5.77-.07,11.54-.2,17.14-.12,4.86-.2,9.41-.21,13.56q-6.51-1.65-13.86-3.31a158.52,158.52,0,0,0-38.44-3.91,83.6,83.6,0,0,0-31.56,6,81.7,81.7,0,0,0-25.83,16.57,78.86,78.86,0,0,0-17.7,25.5,81,81,0,0,0-6.6,32.9v75.84c0,17.82,3.62,32.81,10.77,44.55s17.38,21.23,30.51,28.45c8.38,4.7,18.68,8,30.63,9.75a238.76,238.76,0,0,0,35,2.46c11.87,0,24.21-.51,36.68-1.51,12.66-1,23.93-2.09,34.43-3.19l11.79-1.24v-320h-54.33Zm-77.73,185.19v-79.2c0-6.62,1.18-11.76,3.5-15.29a23.33,23.33,0,0,1,9.65-8.44,44.9,44.9,0,0,1,16.13-4c2.49-.18,5-.27,7.46-.27a100,100,0,0,1,12.86.83,167.27,167.27,0,0,1,20.28,3.89q4.06,1.07,7.43,2.2v128.66c-6.79.46-13.85.73-21.11.81a119.36,119.36,0,0,1-30.19-3.2c-8.26-2-14.85-5.29-20.18-10C1531.86,1673.58,1528.53,1669.53,1528.53,1659.76Z"
      className="animate-svg-path"
      style={{ '--svg-path-length': 1356 } as CSSProperties}
    />
    <path
      d="M1882.17,1514.27l-.28-.22c-7.4-5.64-17.09-9.9-29.65-13a145.37,145.37,0,0,0-35.21-4.39,99.76,99.76,0,0,0-34.23,5.64,77.92,77.92,0,0,0-27.32,16.76,73.88,73.88,0,0,0-17.69,26.6,90.61,90.61,0,0,0-6,33.26v85.86c.67,26.49,9,46.16,24.62,58.48,14.83,11.65,34.51,17.56,58.49,17.56a149.22,149.22,0,0,0,42-6.23,168.67,168.67,0,0,0,39.3-17.14l10.67-6.41-24.63-46.81-11.71,6.33a132.93,132.93,0,0,1-28.35,11.52,99.28,99.28,0,0,1-25.64,3.75c-9.13,0-16.31-1.91-22-5.85-2.64-1.85-6.16-5.46-6.16-15.53v-6.75l123-9.27v-66.56a111.84,111.84,0,0,0-5.32-35.35C1902.31,1534.82,1894.22,1524,1882.17,1514.27Zm-93.69,88.3v-23.7c0-6,1-10.76,2.87-14a22.24,22.24,0,0,1,7.27-7.92,31.31,31.31,0,0,1,10.88-4.3,69.26,69.26,0,0,1,13.42-1.44,58.11,58.11,0,0,1,10.53,1.23,31.06,31.06,0,0,1,10.36,4.19,25.1,25.1,0,0,1,8,8.26c2,3.17,3,7.89,3,14v18.57Z"
      className="animate-svg-path"
      style={{ '--svg-path-length': 1158 } as CSSProperties}
    />
    <polygon
      points="2113.15 1498.71 2071.98 1634.13 2031.15 1498.71 1970.71 1498.71 2043.23 1735.71 2100.17 1735.71 2173.64 1498.71 2113.15 1498.71"
      className="animate-svg-path"
      style={{ '--svg-path-length': 957 } as CSSProperties}
    />
  </svg>
)
