export enum Paths {
  HOME = 'home',
  PROFILE = 'profile',
  CHANGE_ALIAS = 'profile/change-alias',
  CHANGE_GROUP = 'profile/change-group',
  RIDDLE = 'riddle',
  RIDDLE_HISTORY = 'riddle/history',
  TASKS = 'tasks',
  NEWS = 'news',
  EVENTS = 'event',
  CALENDAR = 'calendar',
  IMPRESSUM = 'impressum',
  TOKEN = 'token',
  TOKEN_SCANNED = 'token-scanned',
  COMMUNITY = 'community',
  ORGANIZATION = 'organization',
  ERROR = 'error',
  EXTRA_PAGE = 'page',
  FORM = 'form',
  LEADER_BOARD = 'leaderboard',
  RACE = 'race',
  QR_FIGHT = 'qr-fight',
  TEAMS = 'teams',
  CREATE_TEAM = 'create-team',
  EDIT_TEAM = 'edit-team',
  MY_TEAM = 'my-team',
  TEAM_ADMIN = 'team-admin',
  ACCESS_KEY = 'access-key',
  MAP = 'map'
}

export enum AbsolutePaths {
  HOME = '/home',
  PROFILE = '/profile',
  CHANGE_ALIAS = '/profile/change-alias',
  CHANGE_GROUP = '/profile/change-group',
  RIDDLE = '/riddle',
  RIDDLE_HISTORY = '/riddle/history',
  TASKS = '/tasks',
  NEWS = '/news',
  EVENTS = '/event',
  IMPRESSUM = '/impressum',
  TOKEN = '/token',
  TOKEN_SCANNED = 'token-scanned',
  COMMUNITY = '/community',
  ORGANIZATION = '/organization',
  ERROR = '/error',
  EXTRA_PAGE = '/page',
  FORM = '/form',
  RACE = '/race',
  TEAMS = '/teams',
  CREATE_TEAM = '/create-team',
  EDIT_TEAM = '/edit-team',
  MY_TEAM = '/my-team',
  TEAM_ADMIN = '/team-admin',
  QR_FIGHT = '/qr-fight',
  LEADER_BOARD = '/leaderboard',
  ACCESS_KEY = '/access-key',
  MAP = '/map'
}

export enum ApiPaths {
  CONFIG = '/api/app',
  EVENTS = '/api/events',
  FORM = '/api/form',
  PAGE = '/api/page',
  NEWS = '/api/news',
  RACE = '/api/race',
  FREESTYLE_RACE = '/api/freestyle-race',
  RACE_BY_TEAM = '/api/race-by-team',
  RIDDLE = '/api/riddle',
  RIDDLE_HISTORY = '/api/riddle-history',
  TASK = '/api/task',
  TASK_SUBMIT = '/api/task/submit',
  REFRESH = '/api/control/refresh',
  WARNING = '/api/warning',
  TASK_CATEGORY = '/api/task/category',
  PROFILE = '/api/profile',
  LEVELS = '/api/levels',
  LOCATION = '/api/track-my-group',
  TOKENS = '/api/tokens',
  CHANGE_ALIAS = '/api/profile/change-alias',
  CHANGE_GROUP = '/api/group/select',
  COMMUNITY = '/api/community',
  ORGANIZATION = '/api/organization',
  ACCESS_KEY = '/api/access-key',
  HOME_NEWS = '/api/home/news'
}
