export enum QueryKeys {
  ALIAS_CHANGE = 'ALIAS_CHANGE',
  FORM = 'FORM',
  EXTRA = 'EXTRA',
  EVENTS = 'EVENTS',
  CONFIG = 'CONFIG',
  WARNING = 'WARNING',
  NEWS = 'NEWS',
  USER = 'USER',
  RACE = 'RACE',
  FREESTYLE_RACE = 'FREESTYLE_RACE',
  RIDDLE_HINT = 'RIDDLE_HINT',
  RIDDLE = 'RIDDLE',
  RIDDLE_SUBMIT = 'RIDDLE_SUBMIT',
  QR_LEVELS = 'QR_LEVELS',
  LOCATIONS = 'LOCATIONS',
  TASK_CATEGORIES = 'TASK_CATEGORIES',
  TASK_DETAILS = 'TASK_DETAILS',
  TASK_SUBMIT = 'TASK_SUBMIT',
  TASKS_IN_CATEGORY = 'TASKS_IN_CATEGORY',
  LEADERBOARD = 'LEADERBOARD',
  TOKEN = 'TOKEN',
  COMMUNITY = 'COMMUNITY',
  ORGANIZATION = 'ORGANIZATION',
  ACCESS_KEY = 'ACCESS_KEY',
  HOME_NEWS = 'HOME_NEWS'
}
